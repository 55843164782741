import React, { useState, useEffect } from 'react'
import "../css/Quotation.css"
import { useLocation } from 'react-router-dom';
import { domain } from './Domain';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

const Quotation = () => {
    const location = useLocation();
    const state = location.state

    const { products, cartItems, sessionId, info } = state
    const [total, setTotal] = useState(0)
    const [quotationNumber, setQuotationNumber] = useState('');
    const [base64Images, setBase64Images] = useState({});

    const getProduct = (productId) => {
        const response = products && products.length > 0 && products.find(product => product.id === parseInt(productId))
        return response
    }

    const getTotal = () => {
        let total = 0;
        if (products && cartItems) {
            cartItems.map(item => {
                const foundItem = products.find(dataItem => dataItem.id === item.product)
                total += foundItem.selling_price * item.quantity
            })
        }
        return total
    }

    function addOneMonth(date) {
        // Copy the date to avoid mutating the original
        const newDate = new Date(date);

        // Get current month and year
        let currentMonth = newDate.getMonth();
        let currentYear = newDate.getFullYear();

        // Calculate next month
        currentMonth += 1;

        // Check if we need to increment the year
        if (currentMonth > 11) {
            currentMonth = 0; // January
            currentYear += 1;
        }

        // Set the new month and year
        newDate.setMonth(currentMonth);
        newDate.setFullYear(currentYear);

        return newDate;
    }
    
    const downloadPDFs = () => {
        const input = document.getElementById('pdf-content');

        html2canvas(input, { scale: 2 })
            .then((canvas) => {
                const imgData = canvas.toDataURL('image/png');
                const pdf = new jsPDF('p', 'mm', 'a4');
                const imgWidth = 210; // A4 width in mm (8.27in)
                const imgHeight = canvas.height * imgWidth / canvas.width; // Maintain aspect ratio
                pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);
                pdf.save(`quotation - ${quotationNumber}.pdf`);
            });
    };

    const downloadPDF = () => {
        const input = document.getElementById('pdf-content');
    
        html2canvas(input, { scale: 2 })
            .then((canvas) => {
                const imgData = canvas.toDataURL('image/png');
                const pdf = new jsPDF('p', 'mm', 'a4');
                const imgWidth = 210; // A4 width in mm
                const imgHeight = canvas.height * imgWidth / canvas.width; // Maintain aspect ratio
                const pageHeight = 297; // A4 height in mm
                let heightLeft = imgHeight;
                let position = 0;
    
                // Add the first page
                pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
                heightLeft -= pageHeight;
    
                // Add additional pages
                while (heightLeft > 0) {
                    position -= pageHeight;
                    pdf.addPage();
                    pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
                    heightLeft -= pageHeight;
                }
    
                pdf.save(`quotation-${quotationNumber}.pdf`);
            });
    };
    

    const fetchAndConvertToBase64 = (imageUrl) => {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.onload = function () {
                const reader = new FileReader();
                reader.onloadend = function () {
                    resolve(reader.result.split(',')[1]); // Extract base64 string from data URL
                };
                reader.readAsDataURL(xhr.response);
            };
            xhr.onerror = function () {
                reject(new Error('Failed to fetch image'));
            };
            xhr.open('GET', imageUrl);
            xhr.responseType = 'blob';
            xhr.send();
        });
    };

    useEffect(() => {
        // Fetch and convert images to Base64
        const fetchImages = async () => {
            const base64Map = {};
            for (const item of cartItems) {
                const imageUrl = domain + getProduct(item.product)?.image;
                try {
                    const base64 = await fetchAndConvertToBase64(imageUrl);
                    base64Map[item.id] = base64;
                } catch (error) {
                    console.error('Error converting image to Base64:', error);
                }
            }
            setBase64Images(base64Map);
        };

        fetchImages();
    }, [cartItems]);

    const generateQuotationNumber = () => {
        const randomNumber = Math.floor(Math.random() * 10000); // Generate random number between 0 and 9999
        const formattedNumber = randomNumber.toString().padStart(4, '0'); // Pad with leading zeros if necessary
        setQuotationNumber(`${new Date().getFullYear()}-IPE-${formattedNumber}`);
    };

    useEffect(() => {
        setTotal(getTotal)
        generateQuotationNumber()
    }, [cartItems])
    return (
        <div className='bg'>
            <div className='QuotationHeader' id='pdf-content'>
                <div className='one'>
                    <img src='/images/logo.jpg' />
                    <div>
                        <p>Industrial Parts & Engineering Services Ltd</p>
                        <p>Pioneer House Plot 28, Kampala Floor Rm9 </p>
                        <p>P.O Box 122698 Kampala Uganda </p>
                        <p>Phone: 0392174765/0706314991/0758499705</p>
                        <p> Email: <a>industrialpartsandengineering@gmail.com</a></p>
                    </div>
                </div>
                <div className='two'>
                    <div>
                        <p>{info && info.company}</p>
                        <p>{info && info.location}</p>
                        <p>{info && info.city}</p>
                        <p>Uganda</p>
                        <p>Tax ID: {info && info.tin}</p>
                    </div>
                </div>
                <h2>Pro-Forma Invoice # {quotationNumber}</h2>
                <div className='three'>
                    <div>
                        <h3>Quotation Date:</h3>
                        <p>{new Date().toLocaleDateString()}</p>
                    </div>
                    <div>
                        <h3>Expiration:</h3>
                        <p>{addOneMonth(new Date()).toLocaleDateString()}</p>
                    </div>
                    <div>
                        <h3>Salesperson:</h3>
                        <p>System Generated</p>
                    </div>
                </div>
                <table>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Details</th>
                            <th>Image</th>
                            <th>Quantity</th>
                            <th>Unit Price (Ugx)</th>
                            <th>Amount (Ugx)</th>
                        </tr>
                    </thead>
                    <tbody>
                        {cartItems.map(item => (
                            <tr key={item.id}>
                                <td>{getProduct(item.product)?.product_name}</td>
                                <td>
                                    code: {getProduct(item.product)?.item_code} <br />
                                    part No: {getProduct(item.product)?.part_number} <br />
                                    condition: {getProduct(item.product)?.condition}
                                </td>
                                <td className='text-center'>
                                    {base64Images[item.id] && (
                                        <img src={`data:image/jpeg;base64,${base64Images[item.id]}`} width={50} alt="Product" />
                                    )}
                                </td>

                                <td>{item.quantity} PIECES</td>
                                <td>{getProduct(item.product)?.selling_price.toLocaleString()}</td>
                                <td>{(getProduct(item.product)?.selling_price * item.quantity).toLocaleString()}</td>
                            </tr>
                        ))}

                    </tbody>
                </table>
                <div className='four'>
                    <div className='parent'>
                        <div>
                            <p>Untaxed Amount</p>
                            <p>{total.toLocaleString()}</p>
                        </div>
                        <div>
                            <p>Sales Tax 18%</p>
                            <p>{(total * 0.18).toLocaleString()}</p>
                        </div>
                        <div>
                            <p>Total</p>
                            <p>Ugx {(total * 1.18).toLocaleString()}</p>
                        </div>
                    </div>
                </div>
                <div className='five'>
                    <p className='p'>ORDER BY LPO</p>
                    <div>
                        <p>DELIVERY PERIOD: IMMEDIATELY AFTER LPO</p>
                        <p><b>PAYMENT TYPE:</b> <span>EFT/CASH/CHEQUE</span></p>
                    </div>
                    <div>
                        <p><b>BANK:</b> CENTENARY BANK,  BRANCH-MAPEERA</p>
                        <p><b>ACCOUNT NAME:</b>  INDUSTRIAL PARTS AND ENGINEERING SERVICES LTD</p>
                        <p><b>ACCOUNT NO</b> 3100022148</p>
                        <p><b>TIN NUMBER:</b> 1007756677</p>
                    </div>
                </div>
            </div>
            <div className='QuotationHeader pt-0'>
                <button className='btn btn-danger' onClick={downloadPDF}>Download Quotation</button>
            </div>
        </div>
    )
}

export default Quotation