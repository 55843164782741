import React, { useState, useEffect, useRef } from 'react';
import useApiRequest from './useApiRequest';
import useFetch from './useFetch';
import { domain } from './Domain';
import "../css/CategoryComponent.css"
import { DNA } from 'react-loader-spinner'

const CategoryForm = ({ onAddOrUpdate, name, setName, editingId, setEditingId, inputRef, handleCancleEdit, postRequest, putRequest }) => {

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!name.trim()) return;
        
        if (editingId) {
            const formData = new FormData()
            formData.append("name", name)
            const response = await putRequest(`${domain}/voltage/${editingId}`, formData);
            setEditingId(null);
            onAddOrUpdate(response);
        } else {
            const formData = new FormData()
            formData.append("name", name)
            const response = await postRequest(`${domain}/voltage`, formData);
            onAddOrUpdate(response);
        }
        setName('');
    };

    return (
        <form onSubmit={handleSubmit} className='mb-2'>
            <input
                ref={inputRef}
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Category name"
                className='mb-2'
            />
            <button type="submit" className='btn btn-sm btn-success'>{editingId ? 'Update' : 'Add'}</button>
            <button type='button' disabled={editingId ? false: true} onClick={handleCancleEdit} className='btn btn-sm btn-danger ms-2'>canel update</button>
        </form>
    );
};

const CategoryList = ({ categories, onDelete, onEdit, loading }) => {
    return (
        <ul className='item m-0 p-0'>
        {loading ? 
        <p className='d-flex gap-4 align-items-center'>
            <DNA
                visible={true}
                height="80"
                width="80"
                ariaLabel="dna-loading"
                wrapperStyle={{}}
                wrapperClass="dna-wrapper"
            />
        <span>Loading Voltage ...</span></p>
        :categories && categories.map((category) => (
            <li key={category.id} className='d-flex justify-content-between border-bottom p-2 mb-2 rounded'>
                {category.name}
                <div className='d-flex gap-2'>
                    <button onClick={() => onDelete(category.id)} className='btn btn-sm btn-danger'>Delete</button>
                    <button onClick={() => onEdit(category)} className='btn btn-sm btn-success'>Edit</button>
                </div>
            </li>
        ))}
        </ul>
    );
};

const VoltageComponent = () => {
    const inputRef = useRef()
    const [name, setName] = useState('');
    const [editingId, setEditingId] = useState(null);
    const [categories, setCategories] = useState([]);
    const { postRequest, getRequest, putRequest, deleteRequest } = useApiRequest();
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        fetchCategories();
    }, []);

    const fetchCategories = async () => {
        const response = await  getRequest(`${domain}/voltage`);
        setCategories(response);
        setLoading(false)
    };

    const handleAddOrUpdate = (category) => {
        const index = categories.findIndex((c) => c.id === category.id);
        if (index === -1) {
            setCategories([...categories, category]);
        } else {
            const newCategories = [...categories];
            newCategories[index] = category;
            setCategories(newCategories);
        }
    };

    const handleDelete = async (id) => {
        if(window.confirm("are your sure you want to delete this item")){
            const response = await deleteRequest(`${domain}/voltage/${id}`);
            setCategories(categories.filter((category) => category.id !== id));
        }

    };


    const handleEdit = (category) => {
        setName(category.name);
        setEditingId(category.id);
        inputRef.current.focus()
    };

    const handleCancleEdit = () => {
        setName("");
        setEditingId(null);
        inputRef.current.focus()
    };


    return (
        <div>
            <CategoryForm onAddOrUpdate={handleAddOrUpdate} name={name} setName={setName} editingId={editingId} setEditingId={setEditingId} inputRef={inputRef} handleCancleEdit={handleCancleEdit} postRequest={postRequest} putRequest={putRequest} />
            <CategoryList
                categories={categories}
                onDelete={handleDelete}
                onEdit={handleEdit}
                loading={loading}
            />
        </div>
    );
};

export default VoltageComponent;