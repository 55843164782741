import React, { useState } from 'react'
import { domain } from './Domain'
import { motion } from 'framer-motion'
import "../css/OtherActions.css"
import CategoryComponent from './CategoryComponent'
import BrandComponent from './BrandComponent'
import PowerComponent from './PowerComponent'
import CurrentComponent from './CurrentComponent'
import PulseComponent from './PulseComponent'
import VoltageComponent from './VoltageComponent'
import ProductComponent from './ProductComponent'

const OtherActions = () => {
    const buttons = ["Category", "Brand", "Power", "Voltage", "Current", "Pulse Per Revolution", "Products"]
    const [handleView, sethandleView] = useState(null)
    return (
        <div>
            <div className='d-flex gap-2 otherActions'>
                {buttons.map(button => 
                    <motion.button
                        key={button}
                        initial={{y:10}}
                        animate={{y:0}}
                        whileHover={{scale:1.01}}
                        whileTap={{scale:0.9}}
                        onClick={()=>sethandleView(button)}>
                            {button}
                    </motion.button>
                )}
            </div>
            <h4 className='mt-2'>Viewing {handleView} items / values</h4>
            {handleView === null && <CategoryComponent />}
            {handleView === "Category" && <CategoryComponent />}
            {handleView === "Brand" && <BrandComponent />}
            {handleView === "Power" && <PowerComponent />}
            {handleView === "Voltage" && <VoltageComponent />}
            {handleView === "Current" && <CurrentComponent />}
            {handleView === "Pulse Per Revolution" && <PulseComponent />}
            {handleView === "Products" && <ProductComponent />}
        </div>
    )
}

export default OtherActions