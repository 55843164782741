import React, { useState, useEffect, useCallback } from 'react';
import Header from './Header';
import Footer from './Footer';
import useApiRequest from './useApiRequest';
import { domain } from './Domain';
import { useSessionId } from './SessionContext';
import useFetch from './useFetch';
import "../css/Cart.css";
import { motion } from 'framer-motion';
import InquiryForm from './InquiryForm';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useNavigate } from 'react-router-dom';
import { DNA } from 'react-loader-spinner'

const Cart = () => {
    const navigate = useNavigate();
    const { postRequest } = useApiRequest();
    const { data, loading, refresh, setRefresh } = useFetch(`${domain}/products`);
    const { sessionId, cartItems, handleFetch, cartLoading } = useSessionId();
    const [total, setTotal] = useState(0);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [show, setShow] = useState(false);
    const [err, setErr] = useState(null);
    const [loadingProduct, setLoadingProduct] = useState(false);

    const initial = {
        company: '',
        location: '',
        city: '',
        tin: ''
    };
    const [info, setInfo] = useState(initial);
    const { company, location, tin, city } = info;

    const handleChange = (e) => {
        const { name, value } = e.target;
        setInfo(prev => ({ ...prev, [name]: value }));
    };

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const state = {
        products: data,
        cartItems: cartItems,
        sessionId: sessionId,
        info: info
    };

    const handleQuotation = () => {
        for (const key in info) {
            if (info[key] === '') {
                setErr(`${key} is required`);
                return;
            }
        }
        setErr(null);
        closeModal();
        navigate('/quotation', { state });
    };

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const removeFromCart = async (itemId) => {
        const formData = new FormData();
        formData.append("id", itemId);
        formData.append('session-id', sessionId);
        const data = await postRequest(`${domain}/remove-from-cart`, formData);
        if (data) {
            handleFetch();
        }
    };

    const addToCart = async (item) => {
        const formData = new FormData();
        formData.append("id", item.product);
        formData.append('session-id', sessionId);
        const data = await postRequest(`${domain}/add-to-cart`, formData);
        if (data) {
            handleFetch();
        }
    };

    const getProduct = useCallback((productId) => {
        if (!data || loadingProduct) return { loading: true };

        const product = data.find(product => product.id === parseInt(productId));
        if (product) {
            return { ...product, loading: false };
        }

        return { loading: false, notFound: true };
    }, [data, loadingProduct]);

    const getTotal = () => {
        let total = 0;
        if (data && cartItems) {
            cartItems.forEach(item => {
                const foundItem = data.find(dataItem => dataItem.id === item.product);
                if (foundItem) {
                    total += foundItem.selling_price * item.quantity;
                }
            });
        }
        return total;
    };

    useEffect(() => {
        setTotal(getTotal());
    }, [cartItems, data]);

    return (
        <div>
            <Header />
            <div className='cartItem'>
                <div className='divided flex-wrap gap-4'>
                    <ul>
                        <h4 className='h4 w-100'>Your Shopping Cart {cartItems.length} item(s)</h4>
                        {cartLoading ? <p>Cart items are loading...</p> :
                            cartItems.map(item => {
                                const product = getProduct(item.product);
                                return (
                                    <li key={item.id}>
                                        {product.loading ? (
                                            <p className='d-flex gap-4 align-items-center'>
                                                <DNA
                                                    visible={true}
                                                    height="80"
                                                    width="80"
                                                    ariaLabel="dna-loading"
                                                    wrapperStyle={{}}
                                                    wrapperClass="dna-wrapper"
                                                    />
                                                <span>Loading product details...</span></p>
                                        ) : product.notFound ? (
                                            <p>Product not found</p>
                                        ) : (
                                            <>
                                                <img src={domain + product.image} alt={product.product_name} />
                                                <div className='d-block d-md-flex justify-content-between w-100 align-items-center'>
                                                    <div className='w-75'>
                                                        <h3 className='fs-6 fw-bold'>{product.product_name} - {product.part_number}</h3>
                                                        <em>{product.condition}</em>
                                                        <p>Quantity: {item.quantity} @ {product.selling_price.toLocaleString()}/=</p>
                                                    </div>
                                                    <div className='flex-grow-1'>
                                                        <p>{(product.selling_price * item.quantity).toLocaleString()}/=</p>
                                                    </div>
                                                    <div>
                                                        <button className='btn btn-danger me-2' onClick={() => removeFromCart(item.product)}>-</button>
                                                        <button className='btn btn-success' onClick={() => addToCart(item)}>+</button>
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                    </li>
                                );
                            })}
                    </ul>
                    <div className='flex-grow-1 last'>
                        <h3>Summary</h3>
                        <em>Thank You For Ordering With Us</em>
                        <div className='d-flex justify-content-between'>
                            <p>SUB-TOTAL</p>
                            <p>{total.toLocaleString()}</p>
                        </div>
                        <em>Our products are subject to 18% VAT as required by the Ugandan Government</em>
                        <div className='d-flex justify-content-between'>
                            <p>VAT</p>
                            <p>18%</p>
                        </div>
                        <div className='d-flex justify-content-between'>
                            <p>TOTAL</p>
                            <p>{(total * 1.18).toLocaleString()}</p>
                        </div>
                        <motion.button
                            initial={{ y: 10 }}
                            animate={{ y: 0 }}
                            whileHover={{ scale: 1.01 }}
                            whileTap={{ scale: 0.9 }}
                            onClick={handleShow}
                        >
                            GENERATE QUOTATION
                        </motion.button>
                        <InquiryForm isOpen={isModalOpen} onClose={closeModal} />
                        <em style={{ fontSize: "0.775rem" }} className='pt-3'>In case you have any inquiries, please fill out our <Link onClick={openModal}>contact form</Link> and we shall get back to you within the shortest time possible</em>
                    </div>
                </div>
                <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
                    <Modal.Header closeButton style={{ background: "#007bc7", color: "#fff" }}>
                        <Modal.Title className='fs-5'>Add Quotation Information</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {err && <p className='alert alert-sm alert-danger'>{err}</p>}
                        <p className='mb-2'><em>Please provide this information to be added to your system-generated quotation.</em></p>
                        <input type='text' name='company' value={company} onChange={handleChange} className='mb-2' placeholder='Company name' />
                        <input type='text' name='location' value={location} onChange={handleChange} className='mb-2' placeholder='Address e.g. Plot xxx Kalerwe - Tula Road Kawempe' />
                        <input type='text' name='city' value={city} onChange={handleChange} className='mb-2' placeholder='e.g. Kampala' />
                        <input type='number' name='tin' value={tin} onChange={handleChange} className='mb-2' placeholder='TIN number / Tax ID e.g. 1000034721' />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={handleQuotation}>
                            Save and Proceed
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
            <Footer />
        </div>
    );
};

export default Cart;