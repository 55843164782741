import React, {useState, useEffect} from 'react'
import Header from './Header'
import { ProductCardSkeleton } from './ProductCard';
import ProductCard from './ProductCard';
import { domain } from './Domain';
import { useLocation } from 'react-router-dom';
import NoSearchResults from './NoSearchResults';
import useFetch from './useFetch';
import CartButton from './CartButton';

const Search = () => {
    const location = useLocation()
    const { search } = location.state;
    const {data, loading, error, refresh, setRefresh} = useFetch(`${domain}/products?search=${search}`);

    useEffect(()=>{

    },[search])

    return (
        <div>
            <Header />
            <div className='container-lg p-4'>
                <h4>Search Results for {search}</h4>
                {data && data.length === 0 && <NoSearchResults />}
                <div className="product-list d-flex gap-3 flex-wrap">
                    {loading ? (
                        <React.Fragment>
                            <ProductCardSkeleton />
                            <ProductCardSkeleton />
                            <ProductCardSkeleton />
                            <ProductCardSkeleton />
                            {/* Add more skeletons as needed */}
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            {data && data.map((product) => (
                                <ProductCard
                                    key={product.id}
                                    id={product.id}
                                    name={product.product_name}
                                    partNumber={product.part_number}
                                    stockAmount={product.qty}
                                    price={product.selling_price.toLocaleString()}
                                    image={domain+product.image}
                                />
                            ))}
                        </React.Fragment>
                    )}
                </div>
            </div>
            <CartButton />
        </div>
    )
}

export default Search