import React, {useState} from 'react';
import '../css/InquiryList.css'; // Import external CSS file
import { domain } from './Domain'
import useFetch from './useFetch'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'
import { motion } from 'framer-motion';
import InquiryAnswer from './InquiryAnswer';
import { ToastContainer } from 'react-toastify';

const InquiryList = () => {
    const {data, loading, error, refresh, setRefresh} = useFetch(`${domain}/inquiries`);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selected, setSelected] = useState(null)

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const handleInquiryClick = (inquiry) => {
        if (!inquiry.answered) {
            setSelected(inquiry);
            openModal();
            // Handle click action for unanswered inquiries
        }
    };
    const cardsArray = Array.from({ length: 4 }, (_, index) => index);

    return (
        <div className="container">
            <h4 style={{ marginBottom: '20px' }}>Inquiry List</h4>
            {loading && (
                <div>
                {cardsArray.map((item) => (
                    <div key={item} className="card">
                    <Skeleton width={"30%"} />
                    <Skeleton width={"100%"} count={3} />
                    </div>
                ))}
                </div>
            )}
            {data && data.map(inquiry => (
                <motion.div 
                    key={inquiry.id}
                    className={`card ${inquiry.answered ? 'answered' : ''}`}
                    style={{ cursor: inquiry.answered ? 'not-allowed' : 'pointer' }}
                    onClick={() => handleInquiryClick(inquiry)}
                    whileHover={!inquiry.answered ? { scale: 1.01 } : {}}
                    whileTap={!inquiry.answered ? { scale: 0.9 } : {}}>
                        <div className="heading">{inquiry.name}</div>
                        <div className="text"><strong>Email:</strong> {inquiry.email}</div>
                        <div className="text"><strong>Phone:</strong> {inquiry.phone}</div>
                        <div className="text"><strong>Message:</strong> {inquiry.question}</div>
                        <small className='d-flex justify-content-end text-muted'>created on {new Date(inquiry.created).toLocaleString()}</small>
                </motion.div>
            ))}
            {data && data.length === 0 &&
            <p>There are no inquiries</p>
            }
            <InquiryAnswer isOpen={isModalOpen} onClose={closeModal} selected={selected} refresh={refresh} setRefresh={setRefresh} />
        </div>
    );
}

export default InquiryList;
