// InquiryForm.jsx

import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import useApiRequest from './useApiRequest';
import { domain } from './Domain';
import { notify } from './ProductForm';

const InquiryAnswer = ({ isOpen, onClose, selected, refresh, setRefresh }) => {
  const [answer, setAnswer] = useState('');
  const { postRequest } = useApiRequest();

  const handleSubmit = async(e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("answer", answer)
    formData.append("inquiry", selected.id)
    const response = await postRequest(`${domain}/inquiries/${selected.id}/answers`, formData)
    if(response){
        notify("Response Submitted", "info")
        // Clear the form fields
        setAnswer('');
        setRefresh(!refresh)

        // Close the modal
        onClose()
    }else{
        // Close the modal
        notify("Error sending the Response", "error")
    }
  };

  return (
    <Modal show={isOpen} onHide={onClose} centered>
      <Modal.Header closeButton style={{background:"var(--background)"}}>
        <Modal.Title className='fs-5 text-white'>Response to {selected && selected.email}'s Inquiry</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h6 className='text-muted'>Contact Phone Number</h6>
        <p>{selected && selected.phone}</p>
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="formQuestion">
            <Form.Label>Type Your Answer Below</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              placeholder="Type your answer here..."
              value={answer}
              onChange={(e) => setAnswer(e.target.value)}
              required
            />
          </Form.Group>

          <Button variant="primary" type="submit" className='mt-2'>
            Submit Inquiry Answer
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default InquiryAnswer;
