// ProductCard.js

import React, {useState} from 'react';
import PropTypes from 'prop-types';
import '../css/ProductCard.css'; // Create a CSS file for styling
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'
import '../css/ProductCardSkeleton.css'; // Create a CSS file for skeleton styling
import { domain } from './Domain';
import { useSessionId } from './SessionContext';
import useApiRequest from './useApiRequest';

export const ProductCardSkeleton = () => {
    return (
        <SkeletonTheme baseColor="#ddd" highlightColor="#aaa">
            <div  className="product-card-skeleton">
                <div className="product-card-image-skeleton">
                    <Skeleton height={200} />
                </div>
                <div className="product-card-body-skeleton">
                    <p className='w-100'><Skeleton width="80%" height={16} /></p>
                    <div className="product-card-header-skeleton">
                        <p className='w-100'><Skeleton width="80%" height={12} /></p>
                        <p className='w-100'><Skeleton width="50%" height={12} /></p>
                    </div>
                    <div>
                        <Skeleton width="50%" height={12} />
                        <Skeleton width="40%" height={12} />
                    </div>
                </div>
                <div className="product-card-footer-skeleton text-center">
                    <Skeleton width={150} height={32} />
                </div>
            </div>
        </SkeletonTheme>

    );
};

const ProductCard = ({ id, name, partNumber, stockAmount, price, image }) => {
    const [add, setAdding] = useState(false);
    const { postRequest } = useApiRequest()
    const { sessionId, handleFetch } = useSessionId()
    const handleAddToCart = async() => {
        setAdding(true)
        const formData = new FormData()
        formData.append("id", id)
        formData.append('session-id', sessionId)
        const data = await postRequest(`${domain}/add-to-cart`, formData)
        if(data){
            handleFetch()
            setAdding(false)
        }
        
    };

    return (
        <div className="product-card">
            <div className="product-card-image">
                <img src={image} alt={name} />
            </div>
            <div className="product-card-body">
                <div className="product-card-header">
                    <Link to={`/products-details/${id}`}><h2 className="product-name fs-6 fw-bold">{name}</h2></Link>
                    <p className="part-number">Part Number: {partNumber}</p>
                </div>
                <p className="stock-amount">Stock: {stockAmount}</p>
                <p className="price">Ugx {price}</p>
            </div>
            <div className="product-card-footer">
                <motion.button onClick={handleAddToCart} whileHover={{scale: 1.05}} whileTap={{scale: 0.9}} className='add-to-cart-btn'>{add ? 'Adding To Cart' : 'Add to Cart'} <i className="fa-solid fa-right-to-bracket"></i></motion.button>
            </div>
        </div>
    );
};

ProductCard.propTypes = {
    name: PropTypes.string.isRequired,
    partNumber: PropTypes.string.isRequired,
    stockAmount: PropTypes.number.isRequired,
    price: PropTypes.number.isRequired,
    image: PropTypes.string.isRequired,
    onAddToCart: PropTypes.func.isRequired,
};

export default ProductCard;