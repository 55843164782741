import React from 'react';
import "../css/NoData.css"

const NoDataComponent = ({ showNoData }) => {
    if (!showNoData) {
        return null;
    }

    return (
        <div className='no-data'>
            <div className="no-data-container">
                <h2>No Products Found</h2>
                <p>There are currently no products available in this category.</p>
            </div>
        </div>
    );
};

export default NoDataComponent;
