import React from 'react'
import { Link } from 'react-router-dom'
import Header from './Header'
import { motion } from 'framer-motion'
import "../css/Contact.css"

const Contact = () => {
  return (
    <div>
      <Header />
        <div className='row adress_cont m-auto'>
          <motion.div initial = {{opacity: 0, y: 100}} whileInView={{opacity: 1, y: 0}} transition={{duration: 1, type: 'spring', stiffness: 200, damping: 10}} class="col-md-6">
              <h4>Contact Information</h4>
              <p><b>INDUSTRIAL PARTS AND ENGINEERING SERVICES LTD</b> was incorporated in May 2015 in Uganda with a vision of providing Manufacturing industries with Quality spares, at right cost, in the right quantities, at the right time and in right place. </p>
              <div class="d-flex align-items-center gap-4">
              <i class="fa fa-location" aria-hidden="true"></i>
              <div className='w-100'>
                  <h5>Industrial Parts (U) Ltd</h5>
                  <p>Pioneer House Plot 28 (Opp. Shell Jinja Rd)</p>
                  <p>First Floor Rm 09</p>
                  <p>P.O Box 22625(U)</p>
                  <hr />
              </div>
          </div>
          <div class="d-flex align-items-center gap-4">
              <i class="fa fa-phone" aria-hidden="true"></i>
              <div className='w-100'>
                  <h5>+256 (0) 392-174765 | +256(0) 775-871105 | +256(0) 706-314991</h5>
                  <p>Mon-Fri 8:30am-6:30pm</p>
                  <hr/>
              </div>
          </div>
          <div class="d-flex align-items-center gap-4">
              <i class="fa fa-envelope" aria-hidden="true"></i>
              <div className='w-100'>
                  <h5>info@industrialparts.ug</h5>
                  <p>24/7 online support</p>
                  <hr />
              </div>
          </div>
        </motion.div>
        <motion.div initial = {{opacity: 0, y: -100}} whileInView={{opacity: 1, y: 0}} transition={{duration: 1, type: 'spring', stiffness: 200, damping: 10}} className='col-md-6'>
            <iframe width="100%" className='bg-light' height="400" frameborder="0" style={{border:0}} src="https://www.google.com/maps/embed/v1/place?key=AIzaSyBGY-aKP5KQbjJbf2B2mN6u9909uI5iv50&amp;q=Pioneer House Plot 28 (Opp. Shell Jinja Rd)" allowfullscreen="" title='location'></iframe>
        </motion.div>
        </div>
    </div>
  )
}

export default Contact