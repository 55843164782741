import React, {useState, useEffect} from 'react'
import Header from './Header'
import { ComponentLayOut } from './Home'
import { CategoryLinks } from './Home'
import ProductCard from './ProductCard'
import { useParams } from 'react-router-dom';
import { ProductCardSkeleton } from './ProductCard'
import { domain } from './Domain'
import useFetch from './useFetch'
import NoDataComponent from './NoData'
import { useNavContext } from './NavContext'

const ProductComponent = () => {
    let { slug } = useParams();
    const { mainNav } = useNavContext();
    const {data, loading, error, refresh, setRefresh} = useFetch(`${domain}/products?category=${slug}`);

    useEffect(()=>{
        setTimeout(()=>{
        },1000)
    },[slug])

    function capitalizeWords(str) {
        return str.toLowerCase().split(' ').map(word => {
          return word.charAt(0).toUpperCase() + word.slice(1);
        }).join(' ');
    }

    return (
        <div>
            <h4>currently viewing: {mainNav && mainNav.length > 0 && capitalizeWords(mainNav.find(item => item.slug.toString() === slug.toString()).name)}</h4>
            <div className="product-list d-block d-md-flex gap-3 flex-wrap">
                {loading ? (
                        <React.Fragment>
                            <ProductCardSkeleton />
                            <ProductCardSkeleton />
                            <ProductCardSkeleton />
                            <ProductCardSkeleton />
                            {/* Add more skeletons as needed */}
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            {data && data.length === 0 ? 
                                <NoDataComponent showNoData={true} />
                            :
                                data && data.map((product) => (
                                    <ProductCard
                                        key={product.id}
                                        id={product.id}
                                        name={product.product_name}
                                        partNumber={product.part_number}
                                        stockAmount={product.qty}
                                        price={product.selling_price.toLocaleString()}
                                        image={domain+product.image}
                                    />
                                ))
                            }
                        </React.Fragment>
                    )}
            </div>
        </div>
    )
}

const ProductCategory = () => {
    return(
        <>
            <Header />
            <ComponentLayOut componentName={<ProductComponent />} />
        </>
        
    )
}

export default ProductCategory