// ProductImageSlider.js

import React, { useState } from 'react';
import '../css/ProductImageSlider.css'; // Create a CSS file for styling
import { domain } from './Domain';

const ProductImageSlider = ({ images }) => {
    const [currentImageIndex, setCurrentImageIndex] = useState(0);

    const nextImage = () => {
        const newIndex = (currentImageIndex + 1) % images.length;
        setCurrentImageIndex(newIndex);
    };

    const prevImage = () => {
        const newIndex = (currentImageIndex - 1 + images.length) % images.length;
        setCurrentImageIndex(newIndex);
    };

    const changeImage = (index) => {
        setCurrentImageIndex(index);
    };

    return (
        <div className="product-image-slider">
            <div className="main-image-container">
                <button className="prev-button" onClick={prevImage}>
                    &lt;
                </button>
                <img src={domain+images[currentImageIndex]} alt="Product" className="main-image" />
                <button className="next-button" onClick={nextImage}>
                    &gt;
                </button>
            </div>
            <div className="thumbnail-images">
                {images && images.map((image, index) => (
                    <img
                        key={index}
                        src={domain+image}
                        alt="Product Thumbnail"
                        className={`thumbnail ${index === currentImageIndex ? 'active' : ''}`}
                        onClick={() => changeImage(index)}
                    />
                ))}
            </div>
        </div>
    );
};

export default ProductImageSlider;
