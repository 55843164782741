import React, { useState } from 'react'
import "../css/Header.css"
import { Link, useNavigate } from 'react-router-dom'
import { useLoginContext } from './LoginContext';
import { useActivePage } from './ActivePageContext';
import InquiryForm from './InquiryForm';
import { ToastContainer } from 'react-toastify';
import { useNavContext } from './NavContext';



const Top = () =>{
    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };
    return(
        <>
            <div className='top d-flex align-items-center justify-content-between text-white'>
                <div>
                    <p>Industrial Parts (U) Ltd</p>
                </div>
                <div className='d-flex gap-3 align-items-center'>
                    <Link className='m-0 p-0 text-white'  to="https://wa.me/256775871105?text=Hi,%20I'm%20interested%20in%20your%20services"><i className='fab fa-whatsapp'></i> whatsapp</Link>
                    <Link className='text-white' to='/contact-us'>contact us</Link>
                    <Link onClick={openModal} className='text-white'><span>Inquiries</span> <i class="fa-solid fa-circle-question"></i></Link>
                    <Link className='text-white' to='/login'>login</Link>
                    <i class="fa-brands fa-twitter"></i>
                    <i class="fa-brands fa-facebook-f"></i>
                </div>
                <InquiryForm isOpen={isModalOpen} onClose={closeModal} />
            </div>
            <ToastContainer />
        </>
    )
}

const Navigator = () => {
    const { mainNav } = useNavContext();
    const { activePage, setActivePage } = useActivePage();
    const [search, setSearch] = useState("")

    const navigate = useNavigate()
    const { isLoggedIn, setIsLoggedIn, userInfo, setUserInfo } = useLoginContext();

    const handleSearch = async(e) => {
        e.preventDefault()
        navigate(`/search?name=${search}`, { state: { search: search } });
    }

    return(
        <div className='navigator d-flex align-items-center justify-content-end gap-4 flex-wrap'>
            <nav className='d-flex gap-3'>
                {isLoggedIn === true && <Link to={"/dashboard"} onClick={()=>setActivePage("dashboard")} className={activePage === 'dashboard' ? 'active' : ''}>Dashboard</Link>}
                <Link to={"/"} onClick={()=>setActivePage("")} className={activePage === '' ? 'active' : ''}>HOME</Link>
                {mainNav && mainNav.map(item => (
                    item.header && <Link key={item.id} to={`/products/${item.slug}`} onClick={()=>setActivePage(item.slug)} className={activePage === item.slug ? 'active' : ''}>{item.name}</Link>
                ))}
                {/* 
                <Link to={"/products/soft-starters"} onClick={()=>setActivePage("soft-starters")} className={activePage === 'soft-starters' ? 'active' : ''}>SOFT STARTERS</Link>
                <Link to={"/products/direct-online-starters"} onClick={()=>setActivePage("direct-online-starters")} className={activePage === 'direct-online-starters' ? 'active' : ''}>DIRECT ONLINE STATERS</Link>
                <Link to={"/products/servo-drives"} onClick={()=>setActivePage("servo-drives")} className={activePage === 'servo-drives' ? 'active' : ''}>SERVO DRIVES</Link>
                <Link to={"/products/contactors"} onClick={()=>setActivePage("contactors")} className={activePage === 'contactors' ? 'active' : ''}>CONTACTORS</Link>
                <Link to={"/products/breakers-mcb"} onClick={()=>setActivePage("breakers-mcb")} className={activePage === 'breakers-mcb' ? 'active' : ''}>BREAKER (MCB)</Link>
                <Link to={"/products/circuit-breakers-mccb"} onClick={()=>setActivePage("circuit-breakers-mccb")} className={activePage === 'circuit-breakers-mccb' ? 'active' : ''}>CIRCUIT BREAKERS (MCCB)</Link>
                */}
                <Link to={"/contact-us"} onClick={()=>setActivePage("contact")} className={activePage === 'contact' ? 'active' : 'd-none'}>CONTACT US</Link>
            </nav>

            <form className='d-flex gap-2'>
                <input type='search' placeholder='search industrial parts' name='name' onChange={(e)=>setSearch(e.target.value)} />
                <button className='btn border' onClick={handleSearch}><i class="fa-solid fa-magnifying-glass"></i></button>
            </form>

            {/*isLoggedIn === false ?
                <div>
                    <Link to={"/login"}>Login</Link>
                    <Link to={"/register"}>Create Account</Link>
                </div>
                :
                <div className='d-flex align-items-center gap-2'>
                    <img className='img-thumbnail' src={domain+userInfo.image} />
                    <p>Welcome {userInfo.name}</p>
                    <Link className="bg-danger" onClick={handleLogOut}>Logout</Link>
                </div>
            */}
        </div>
    )
}

const Header = () => {
  return (
    <div className='header'>
        <Top />
        <Navigator />
    </div>
  )
}

export default Header