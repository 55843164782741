
import React, {useState, useRef} from 'react';
import { domain } from './Domain';
import { motion } from 'framer-motion';
import '../css/ForgotPassword.css'; 

export default function ForgotPassword(){
    const [email, setEmail] = useState("");
    const [isPending, setIsPending] = useState(false);
    const regRefSuccess = useRef();

    function validateForm() {
        return email.length > 0;
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        setIsPending(true);
        regRefSuccess.current.style.display = "block";
        regRefSuccess.current.textContent = "Sending registration data ..."
        let form = e.target;
        let data = new FormData(form);
        const requestOptions = {
            method: 'POST',
            cors: "cors",
            body: data
        };
        fetch(`${domain}/accounts/password-reset`, requestOptions)
        .then((res) => res.json())
        .then((data) => {
            //console.log(data);
            form.reset();
            regRefSuccess.current.textContent = data['message']
            setTimeout(function(){
                regRefSuccess.current.textContent = '';
                regRefSuccess.current.style.display = "none";
            },5000);
            setIsPending(false);
        })
        .catch((err) => console.log(err))
    }

    return(
        <motion.div
            initial={{y: 500}}
            animate={{y: 200}}
            className='forgotPassword'>
                <div className='p-2'>
                    <i className="fa-solid d-none fa-user-tie fa-2x"></i>
                    <h1 className='fs-5 pt-2 lead fw-bold fg'>Forgot Password</h1>
                    <p className='lead fs-6'>Please provide us with a valid username or email Address so as to send you reset instructions</p>
                </div>
                <p ref={regRefSuccess} className='text-success fw-bold' style={{display:"none"}}></p>
                <form onSubmit={handleSubmit}>
                    <div className="input-group mb-3">
                        <span className="input-group-text"><i className="fa-solid fa-user-secret"></i></span>
                        <input type="text" name='username' className="form-control" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Username or email address" aria-label="Username"/>
                    </div>
                    {! isPending && <motion.button whileHover={{scale: 1.05}} whileTap={{scale: 0.9}} className='btn btn-success w-100 mb-3' type="submit" disabled={!validateForm()}>Reset Password <i className="fa-solid fa-right-to-bracket"></i></motion.button>}
                    {isPending && <button className='btn btn-success w-100 mb-3' disabled>Requesting Reset Instructions ... </button>}
                </form>
        </motion.div>
    )
}