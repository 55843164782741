import React, {useEffect, useState} from 'react'
import { Link, json } from 'react-router-dom'
import "../css/Dashboard.css"
import { menuItems } from './Data'
import { useLoginContext } from './LoginContext'
import { domain } from './Domain'
import { useNavigate } from 'react-router-dom';
import useApiRequest from './useApiRequest'
import Profile from './Profile'
import Header from './Header'
import Footer from './Footer'
import ProductForm from './ProductForm'
import InquiryList from './Inquiries'
import OtherActions from './OtherActions'
import { useActivePage } from './ActivePageContext'

const LandingPage = () => {
    return (
        <div className="landing-page">
            <header>
                <div className="container">
                    <h1>Welcome to Industrial Parts Ltd Admin Console</h1>
                    <p>A platform for managing most activities</p>
                </div>
            </header>
            <main>
                <section className="about">
                    <div className="container">
                        <p>Introducing an innovative system designed to revolutionize inventory management and customer interaction, the Stock Record and Inquiry Management System (SRIMS) represents a cutting-edge solution tailored for modern business needs. SRIMS seamlessly integrates advanced stock recording capabilities, inquiry creation and storage functionalities, and automated performance statistics generation.</p>
                        <p>This system not only simplifies the process of managing stock records but also enhances customer service by providing a robust platform for creating and tracking inquiries. Moreover, SRIMS leverages automation to deliver real-time performance insights, empowering us to make informed decisions swiftly and efficiently. Designed with scalability and user-friendliness in mind, SRIMS promises to be the cornerstone of operational efficiency for our businesses across various industries.</p>
                    </div>
                </section>
                <section className="contact">
                    <div className="container">
                        <h2>Contact Us</h2>
                        <p>If you have any questions or would like to learn more about our services, please contact us at:</p>
                        <p>Email: contact@universityexam.com | Phone: 123-456-7890</p>
                    </div>
                </section>
            </main>
        </div>
    );
};

const Dashboard = () => {
    const { postRequest, response } = useApiRequest()
    const { setActivePage } = useActivePage();
    const navigate = useNavigate();
    const initial = {
        id: null, 
        token: null, 
        name: null, 
        date: null,
        image: null,
        role: null,
        is_super:null,
        user_type:null
    }
    const { userInfo, setUserInfo, setIsLoggedIn } = useLoginContext();
    const [active, setActive] = useState(null)
    const handleClick = (e) => {
        setActive(e)
    }

    const handleLogOut = async() => {
        const response = await postRequest(`${domain}/accounts/logout`, {})
        if(response['message'] === "logout successfull"){
            setUserInfo(initial)
            localStorage.setItem('isLoggedIn', false)
            setIsLoggedIn(false)
            navigate("/")
        }
    }

    useEffect(()=>{
        setActivePage('dashboard')
    },[])

    return (
        <>
            <Header />
            <div className="home-container">
                <div className="side-panel">
                    <div className='d-none d-md-flex flex-column justify-content-center align-items-center pb-3 pt-3'>
                        <img src={domain+`${userInfo.image}`} style={{width:150, height:150, objectFit:"cover", objectPosition:"center"}} className='img-thumbnail rounded-circle img-fluid' alt='userProfile' />
                        <figcaption>{userInfo.name}</figcaption>
                        <small className='text-muted'>Registered on {new Date(userInfo.date).toLocaleString()}</small>
                    </div>
                    
                    {userInfo.role === "Lecturer" && menuItems.map((item, index) => (
                        <Link onClick={()=>handleClick(index)} key={index}>{item.title}</Link>
                    ))}
                    {userInfo.role === "Student" &&
                        <>
                            <Link onClick={()=>handleClick(4)}>Notifications</Link>
                            <Link onClick={()=>handleClick(4)}>Exam Results</Link>
                            <Link onClick={()=>handleClick(5)}>Attempt Exam</Link>
                        </>
                    }
                    <Link onClick={()=>handleClick(null)}>Home</Link>
                    <Link onClick={()=>handleClick(0)}>Add Products</Link>
                    <Link onClick={()=>handleClick(1)}>Enquiries</Link>
                    <Link onClick={()=>handleClick(2)}>Other Actions</Link>
                    <Link onClick={()=>handleClick(6)}>My Profile</Link>
                    <Link onClick={handleLogOut} className='bg-danger text-white'>Logout of the system</Link>
                </div>
                <div className="scrollable-content">
                    { active === null && <LandingPage /> }
                    { active === 0 && <ProductForm /> }
                    { active === 1 && <InquiryList /> }
                    { active === 2 && <OtherActions /> }
                    { active === 6 && <Profile /> }
                </div>
            </div>
            <Footer />
        </>
        
    )
}

export default Dashboard