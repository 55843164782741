// ActivePageContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';
import { domain } from './Domain';
import useApiRequest from './useApiRequest';

const SessionIdContext = createContext();

export const useSessionId = () => {
  const context = useContext(SessionIdContext);
  if (!context) {
    throw new Error('useSessionId must be used within an SessionIdProvider');
  }
  return context;
};

export const SessionIdProvider = ({ children }) => {
    const [cartLoading, setCartLoading] = useState(true)
    const [cartItems, setCartItems] = useState([]);
    const [sessionId, setSessionId] = useState('');
    const {getRequest} = useApiRequest()


    const handleFetch = async() => {
        const data = await getRequest(`${domain}/cart?session-id=${sessionId}`)
        if(data){
            setCartItems(data)
            setCartLoading(false)
        }
    }

    const generateSessionId = () => {
        return 'session_' + Math.random().toString(36).slice(2, 9);
    };

    useEffect(() => {
        // Check if session ID exists in localStorage
        let sessionIdFromStorage = localStorage.getItem('sessionId');
        if (!sessionIdFromStorage) {
            // Generate a new session ID if it doesn't exist
            sessionIdFromStorage = generateSessionId();
            localStorage.setItem('sessionId', sessionIdFromStorage);
        }else{
            setSessionId(sessionIdFromStorage);
            sessionId !== '' && handleFetch()
        }
    }, [sessionId]);

    const value = {
        sessionId,
        setSessionId,
        cartItems, 
        setCartItems,
        handleFetch,
        cartLoading
    };
    return <SessionIdContext.Provider value={value}>{children}</SessionIdContext.Provider>;
};
