import React, { useState, useEffect, useCallback, useRef } from 'react';
import '../css/Home.css'; // Create a CSS file for styling
import useFetch from './useFetch';
import { domain } from './Domain';
import { Link } from 'react-router-dom';
import Footer from './Footer';
import ProductCard from './ProductCard'
import { motion, useAnimate } from 'framer-motion';
import { ProductCardSkeleton } from './ProductCard';
import NoDataComponent from './NoData';
import { useNavContext } from './NavContext';
import CartButton from './CartButton';


const Welcome = () => {
    const {data, loading, error, refresh, setRefresh} = useFetch(`${domain}/products`);

    useEffect(()=>{
        setTimeout(()=>{
        },3000)
    },[])
    return(
        <div className='welcome d-flex flex-column'>
            <h4>Take time to view our products</h4>
            <div className="product-list d-flex gap-3 flex-wrap">
                {loading ? (
                        <React.Fragment>
                            <ProductCardSkeleton />
                            <ProductCardSkeleton />
                            <ProductCardSkeleton />
                            <ProductCardSkeleton />
                            {/* Add more skeletons as needed */}
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            {data && data.length === 0 ? 
                                <NoDataComponent showNoData={true} />
                            :
                                data && data.map((product) => (
                                    <ProductCard
                                        key={product.id}
                                        id={product.id}
                                        name={product.product_name}
                                        partNumber={product.part_number}
                                        stockAmount={product.qty}
                                        price={product.selling_price.toLocaleString()}
                                        image={domain+product.image}
                                    />
                                ))
                            }
                        </React.Fragment>
                    )
                }
            </div>
        </div>
    )
}

export const CategoryLinks = ({ handleClick }) => {
    const { mainNav } = useNavContext();
    return (
      <>
        {mainNav && mainNav.map((item, index) => (
          !item.header && <Link key={index} to={`/products/${item.slug}`}>
            {item.name}
          </Link>
        ))}
      </>
    );
};

export const ComponentLayOut = ({componentName}) => {
    const handleClick = (e) => {
        //setActive(e)
        //navigate('/contact-us') 
    }
    return (
        <>
            <div className="home-container">
                <div className="side-panel">
                    <CategoryLinks handleClick={handleClick} />
                </div>
                <div className="scrollable-content">
                    {componentName}
                </div>
            </div>
            <CartButton />
            <Footer />
        </>
    );
}

const Home = () => {
    return(
        <ComponentLayOut componentName={<Welcome />} />
    )
}

export default Home;
