import React, { useState, useEffect } from 'react';
import useFetch from './useFetch';
import { domain } from './Domain';
import useApiRequest from './useApiRequest'
import { toast, ToastContainer, Slide } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import "../css/ProductForm.css"
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const CONDITIONS = [
    { value: "New with warranty (Original Packaging)", label: "New with warranty (Original Packaging)" },
    { value: "Never been used (Not in Original Packaging)", label: "Never been used (Not in Original Packaging)" },
    { value: "Refurbished", label: "Refurbished" }
];
  
const CONTACTS = [
    { value: "2NO + 2NC", label: "2NO + 2NC" },
    { value: "1NO + 1 NC", label: "1NO + 1 NC" },
    { value: "NO", label: "NO" },
    { value: "3NO + 1NC", label: "3NO + 1NC" },
    { value: "2NO + 2NC", label: "2NO + 2NC" },
    { value: "0NO + 4NC", label: "0NO + 4NC" }
];
  
const MOUNTING_LOCATION = [
    { value: "FRONT", label: "FRONT" },
    { value: "SIDE", label: "SIDE" }
];
  
const NO_OF_POLES = [
    { value: "SINGLE", label: "SINGLE" },
    { value: "DOUBLE", label: "DOUBLE" },
    { value: "THREE", label: "THREE" },
    { value: "FOUR", label: "FOUR" }
];
  
const MECHANISM = [
    { value: "NPN NO", label: "NPN NO" },
    { value: "NPN NC", label: "NPN NC" },
    { value: "NPN NO+NC", label: "NPN NO+NC" },
    { value: "PNP NO", label: "PNP NO" },
    { value: "PNP NC", label: "PNP NC" },
    { value: "PNP NO+NC", label: "PNP NO+NC" },
    { value: "SCR Controllable Silicon NO", label: "SCR Controllable Silicon NO" },
    { value: "SCR Controllable Silicon NC", label: "SCR Controllable Silicon NC" },
    { value: "Voltage output", label: "Voltage output" },
    { value: "Line-driver output", label: "Line-driver output" }
];
  
const SIZE = [
    { value: "M8 2mm", label: "M8 2mm" },
    { value: "M12 4mm", label: "M12 4mm" },
    { value: "M18 5mm", label: "M18 5mm" },
    { value: "M20 8mm", label: "M20 8mm" },
    { value: "M22 10mm", label: "M22 10mm" },
    { value: "M30 15mm", label: "M30 15mm" }
];
  
const COIL_VOLTAGE = [
    { value: "12VDC", label: "12VDC" },
    { value: "24VDC", label: "24VDC" },
    { value: "110VAC", label: "110VAC" },
    { value: "220VAC", label: "220VAC" },
    { value: "380-400VAC", label: "380-400VAC" }
];

export const notify = (message, type) => {
    let toastType;
    switch(type) {
        case 'success':
            toastType = toast.success;
            break;
        case 'error':
            toastType = toast.error;
            break;
        case 'info':
            toastType = toast.info;
            break;
        default:
            toastType = toast.info;
            break;
    }

    toastType(
        message, {
            position: 'bottom-right',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        }
    );
};

  

const ProductForm = ({editingId, selected, fetchProducts}) => {
    const { postRequest, response, deleteRequest, putRequest } = useApiRequest()
    const {data, loading, error, refresh, setRefresh} = useFetch(`${domain}/product-dropdowns`);
    const [load, setLoad] = useState(false)
    const initial = {
        category: '',
        part_number: '',
        item_code: '',
        brand: '',
        product_name: '',
        power: '',
        current_from: '',
        current_to: '',
        watts: '',
        ohms: '',
        pressure_range_from: '',
        pressure_range_to: '',
        voltage: '',
        coil_voltage: '',
        pulse_per_revolution: '',
        images: [],
        no_of_poles: '',
        mechanism: '',
        mounting_location: '',
        contacts: '',
        size: '',
        condition: '',
        cost_price: '',
        selling_price: '',
        qty: '',
        details: '',
        datasheet: [],
    }
    const [formData, setFormData] = useState(initial);

    const [images, setImages] = useState([]);
    const [datasheets, setDatasheets] = useState([]);

    const modules = {
        toolbar: [
            [{header: [1,2,3,4,5,6, false]}],
            [{font: []}],
            [{size: []}],
            [{color: []}],
            [{background: []}],
            ['bold','italic','underline','strike','blockquote'],
            [
                {"list": "ordered"},
                {"list": "bullet"},
                {"indent": "-1"},
                {"indent": "+1"}
            ],
            ["link","image","video"],
            ["clean"],
        ]
    }

    const handleChange = (e) => {
        if (e.target.name === 'images') {
            setImages([...images, ...e.target.files]);
        }else if(e.target.name === "datasheet"){
            setDatasheets([...datasheets, ...e.target.files]);
        } else {
            setFormData({ ...formData, [e.target.name]: e.target.value });
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const formDataWithImages = new FormData();
        if(editingId){
            delete formData.images
            delete formData['image']
            delete formData.datasheet
        }
        for (const key in formData) {
            if (key === 'images') {
                images.forEach((image) => {
                    formDataWithImages.append('images', image);
                });
            }if (key === 'datasheet') {
                datasheets.forEach((datasheet) => {
                    formDataWithImages.append('datasheet', datasheet);
                });
            } else {
                //dont add items that are empty or null to formdata for submission of editing
                if(editingId){
                    (formData[key] !== null || formData[key !== '']) && formDataWithImages.append(key, formData[key])
                }else{
                    formDataWithImages.append(key, formData[key])
                }
            }
        }
        setLoad(true)
        if(editingId){
            const response = await putRequest(`${domain}/products/${editingId}`, formDataWithImages)
            if(response){
                notify("Product Edit Successfull", "info")
                fetchProducts()
            }
            
        }else{
            const response = await postRequest(`${domain}/products`, formDataWithImages)
            if(response){
                notify("Product Added Successfully", "info")
            }
        }

        setLoad(false)

        //resetiing the form after submission if was a post request performed
        if(!editingId){
            setFormData(initial)
            setImages([])
            setDatasheets([])
            e.target.reset()
        }
    };

    useEffect(()=> {
        editingId && setFormData(selected)
    },[])

    return (
        <div className='productPage'>
            <ToastContainer />
            <svg className='d-none' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#0099ff" fill-opacity="1" d="M0,64L21.8,85.3C43.6,107,87,149,131,170.7C174.5,192,218,192,262,208C305.5,224,349,256,393,250.7C436.4,245,480,203,524,192C567.3,181,611,203,655,186.7C698.2,171,742,117,785,106.7C829.1,96,873,128,916,138.7C960,149,1004,139,1047,138.7C1090.9,139,1135,149,1178,154.7C1221.8,160,1265,160,1309,160C1352.7,160,1396,160,1418,160L1440,160L1440,0L1418.2,0C1396.4,0,1353,0,1309,0C1265.5,0,1222,0,1178,0C1134.5,0,1091,0,1047,0C1003.6,0,960,0,916,0C872.7,0,829,0,785,0C741.8,0,698,0,655,0C610.9,0,567,0,524,0C480,0,436,0,393,0C349.1,0,305,0,262,0C218.2,0,175,0,131,0C87.3,0,44,0,22,0L0,0Z"></path></svg>
            <svg className='d-none' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#0099ff" fill-opacity="1" d="M0,64L21.8,106.7C43.6,149,87,235,131,229.3C174.5,224,218,128,262,117.3C305.5,107,349,181,393,176C436.4,171,480,85,524,85.3C567.3,85,611,171,655,181.3C698.2,192,742,128,785,90.7C829.1,53,873,43,916,42.7C960,43,1004,53,1047,64C1090.9,75,1135,85,1178,74.7C1221.8,64,1265,32,1309,37.3C1352.7,43,1396,85,1418,106.7L1440,128L1440,0L1418.2,0C1396.4,0,1353,0,1309,0C1265.5,0,1222,0,1178,0C1134.5,0,1091,0,1047,0C1003.6,0,960,0,916,0C872.7,0,829,0,785,0C741.8,0,698,0,655,0C610.9,0,567,0,524,0C480,0,436,0,393,0C349.1,0,305,0,262,0C218.2,0,175,0,131,0C87.3,0,44,0,22,0L0,0Z"></path></svg>
            {loading &&
            <div style={{ textAlign: 'center', marginTop: '20px' }}>
                <h2>Loading...</h2>
                {/* You can use a spinner or any other loading animation here */}
                <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">Loading...</span>
                </div>
            </div>
            }
            {data &&
            <div className='productForm'>
                <h4>Add Product</h4>
                {console.log(formData)}
                <form onSubmit={handleSubmit} className='d-block d-md-flex gap-4' encType="multipart/form-data">
                    <div className='w-100 w-md-50 flex-grow-1'>
                        <div className='d-flex gap-2'>
                            <div className='w-50 flex-grow-1'>
                                <label>Category</label>
                                <select name="category" value={formData.category} onChange={handleChange}>
                                    <option value="">Select Category</option>
                                    {data.all_categories.map((category) => (
                                    <option key={category.id} value={category.id}>
                                        {category.name}
                                    </option>
                                    ))}
                                </select>
                            </div>
                            <div className='w-50 flex-grow-1'>
                                <label>Brand:</label>
                                <select name="brand" value={formData.brand} onChange={handleChange}>
                                    <option value="">Select Brand</option>
                                    {data.all_brands.map((brand) => (
                                        <option key={brand.id} value={brand.id}>
                                            {brand.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div>
                            <label>Product Name:</label>
                            <input
                                type="text"
                                name="product_name"
                                placeholder='product name'
                                value={formData.product_name}
                                onChange={handleChange}
                            />
                        </div>
                        <div>
                            <label>Part Number:</label>
                            <input
                                type="text"
                                name="part_number"
                                placeholder='product part number'
                                value={formData.part_number}
                                onChange={handleChange}
                            /> 
                        </div>
                        <div>
                            <label>Item Code:</label>
                            <input
                                type="text"
                                name="item_code"
                                placeholder='item code'
                                value={formData.item_code}
                                onChange={handleChange}
                            />
                        </div>
                        {(formData.category === "1" || formData.category === "2" || formData.category === "4") &&
                        <div>
                            <label>Power:</label>
                            <select name="power" value={formData.power} onChange={handleChange}>
                            <option value="">Select Power</option>
                                {data.all_power.map((power) => (
                                <option key={power.id} value={power.id}>
                                    {power.name}
                                </option>
                                ))}
                            </select>
                        </div>
                        }
                        {(formData.category === "12" || formData.category === "16") &&
                        <div className='d-flex gap-2'>
                            <div className='w-50 flex-grow-1'>
                                <label>Current From:</label>
                                <input
                                    type="number"
                                    name="current_from"
                                    placeholder='current from'
                                    value={formData.current_from}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className='w-50 flex-grow-1'>
                                <label>Current To:</label>
                                <input
                                    type="number"
                                    name="current_to"
                                    placeholder='current to'
                                    value={formData.current_to}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                        }
                        {(formData.category === "13" || formData.category === "15" || formData.category ==="19") &&
                        <div>
                            <label>Watts:</label>
                            <input
                                type="number"
                                name="watts"
                                placeholder='watts'
                                value={formData.watts}
                                onChange={handleChange}
                            />
                        </div>
                        }
                        {(formData.category === "18") &&
                        <div>
                            <label>Ohms:</label>
                            <input
                                type="number"
                                name="ohms"
                                placeholder='ohms'
                                value={formData.ohms}
                                onChange={handleChange}
                            />
                        </div>
                        }
                        {(formData.category === "20") &&
                        <div className='d-flex gap-2'>
                            <div className='w-50 flex-grow-1'>
                                <label>Pressure Range From:</label>
                                <input
                                    type="number"
                                    name="pressure_range_from"
                                    placeholder='pressure from'
                                    value={formData.pressure_range_from}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className='w-50 flex-grow-1'>
                                <label>Pressure Range To:</label>
                                <input
                                    type="number"
                                    name="pressure_range_to"
                                    placeholder='pressure to'
                                    value={formData.pressure_range_to}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                        }
                        {(formData.category === "5" || formData.category === "19") &&
                        <div>
                            <label>Voltage:</label>
                            <select name="voltage" value={formData.voltage} onChange={handleChange}>
                                <option value="">Select Voltage</option>
                                {data.all_voltage.map((voltage) => (
                                <option key={voltage.id} value={voltage.id}>
                                    {voltage.name}
                                </option>
                                ))}
                            </select>
                        </div>
                        }
                        {formData.category === "8" &&
                        <div>
                            <label>Coil Voltage:</label>
                            <select name="coil_voltage" value={formData.coil_voltage} onChange={handleChange}>
                                <option value="">Select Coil Voltage</option>
                                {COIL_VOLTAGE.map((voltage) => (
                                <option key={voltage.value} value={voltage.value}>
                                    {voltage.label}
                                </option>
                                ))}
                            </select>
                        </div>
                        }
                        {(formData.category === "8") &&
                        <div>
                            <label>Pulse Per Revolution:</label>
                            <select name="pulses_per_revolution" value={formData.pulses_per_revolution} onChange={handleChange}>
                                <option value="">Select Pulses per Revolution</option>
                                {data.all_pulses_per_revolution.map((pulse) => (
                                <option key={pulse.id} value={pulse.name}>
                                    {pulse.name}
                                </option>
                                ))}
                            </select>
                        </div>
                        }
                        {(formData.category === "5" || formData.category === "6" || formData.category === "7") &&
                        <div>
                            <label>No. of Poles:</label>
                            <select name="no_of_poles" value={formData.no_of_poles} onChange={handleChange}>
                                <option value="">Select Number of Poles</option>
                                {NO_OF_POLES.map((poles) => (
                                <option key={poles.value} value={poles.value}>
                                    {poles.label}
                                </option>
                                ))}
                            </select>
                        </div>
                        }
                        {(formData.category === "8" || formData.category === "9" || formData.category === "10") &&
                        <div>
                            <label>Mechanism:</label>
                            <select name="mechanism" value={formData.mechanism} onChange={handleChange}>
                                <option value="">Select Mechanism</option>
                                {MECHANISM.map((mech) => (
                                <option key={mech.value} value={mech.value}>
                                    {mech.label}
                                </option>
                                ))}
                            </select>
                        </div>
                        }
                        {(formData.category === "21") &&
                        <div>
                            <label>Mounting Location:</label>
                            <select name="mounting_location" value={formData.mounting_location} onChange={handleChange}>
                                <option value="">Select Mounting Location</option>
                                {MOUNTING_LOCATION.map((location) => (
                                <option key={location.value} value={location.value}>
                                    {location.label}
                                </option>
                                ))}
                            </select>
                        </div>
                        }
                        {(formData.category === "9" || formData.category === "21") &&
                        <div>
                            <label>Contacts:</label>
                            <select name="contacts" value={formData.contacts} onChange={handleChange}>
                                <option value="">Select Contacts</option>
                                {CONTACTS.map((contact) => (
                                <option key={contact.value} value={contact.value}>
                                    {contact.label}
                                </option>
                                ))}
                            </select>
                        </div>
                        }
                        {(formData.category === "9") &&
                        <div>
                            <label>Size:</label>
                            <select name="size" value={formData.contacts} onChange={handleChange}>
                                <option value="">Select Size</option>
                                {SIZE.map((size) => (
                                <option key={size.value} value={size.value}>
                                    {size.label}
                                </option>
                                ))}
                            </select>
                        </div>
                        }
                        <div>
                            <label>Condition:</label>
                            <select name="condition" value={formData.condition} onChange={handleChange}>
                                <option value="">Select Condition</option>
                                {CONDITIONS.map((condition) => (
                                <option key={condition.value} value={condition.value}>
                                    {condition.label}
                                </option>
                                ))}
                            </select>
                        </div>
                    </div>

                    <div className='w-100 w-md-50 flex-grow-1'>
                        <div className='d-flex gap-2'>
                            <div>
                                <label>Cost Price:</label>
                                <input
                                    type="number"
                                    name="cost_price"
                                    placeholder='product cost price'
                                    value={formData.cost_price}
                                    min={1}
                                    onChange={handleChange}
                                />
                            </div>
                            <div>
                                <label>Selling Price:</label>
                                <input
                                    type="number"
                                    name="selling_price"
                                    min={1}
                                    placeholder='product selling price'
                                    value={formData.selling_price}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                        <div>
                            <label>Quantity:</label>
                            <input
                                type="number"
                                name="qty"
                                placeholder='product quantity'
                                value={formData.qty}
                                onChange={handleChange}
                            />
                        </div>
                        <div>
                            <label>Details:</label>
                            <textarea
                                className='d-none'
                                name="details"
                                value={formData.details}
                                placeholder='product description'
                                onChange={handleChange}
                            />
                            <ReactQuill 
                                theme="snow" 
                                value={formData.details} // Set the value from notificationData
                                onChange={(content) => {
                                    if(content !=="<p><br></p>"){
                                        setFormData((prevData) => ({
                                            ...prevData,
                                            details: content // Update the 'value' property in notificationData
                                        }));
                                    }else{
                                        setFormData((prevData) => ({
                                            ...prevData,
                                            details: "" // Update the 'value' property in notificationData
                                        }));
                                    }
                                }}
                                className='editor-input'
                                style={{background:"white"}}
                                placeholder="Type the examination question here ....." // Add the placeholder option
                                //style={editorStyles}
                                modules = {modules} />
                        </div>
                        {!editingId &&
                        <div>
                            <label>Add Product Datasheet:</label>
                            <input
                                type="file"
                                name="datasheet"
                                className='border-0'
                                accept='application/pdf'
                                multiple
                                onChange={handleChange}
                                /*
                                onChange={(e) =>
                                setFormData({ ...formData, datasheet: e.target.files[0] })
                                }
                                */
                            />
                        </div>
                        }
                        {!editingId &&
                        <div>
                            <label>Add Product Images:</label>
                            <input
                                type="file"
                                name="images"
                                className='border-0'
                                multiple
                                accept='image/*'
                                onChange={handleChange}
                            />
                        </div>
                        }
                        {editingId ?
                        <button className='btn btn-success mt-2' type="submit">{load ? "Editting Product ...":"Edit Product"}</button>
                        :
                        <button className='btn btn-success mt-2' type="submit">{load ? "Adding Product ...":"Add Product"}</button>
                        }
                        
                    </div>
                </form>
            </div>
            }
            <svg className='d-none' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#0099ff" fill-opacity="1" d="M0,64L21.8,85.3C43.6,107,87,149,131,170.7C174.5,192,218,192,262,208C305.5,224,349,256,393,250.7C436.4,245,480,203,524,192C567.3,181,611,203,655,186.7C698.2,171,742,117,785,106.7C829.1,96,873,128,916,138.7C960,149,1004,139,1047,138.7C1090.9,139,1135,149,1178,154.7C1221.8,160,1265,160,1309,160C1352.7,160,1396,160,1418,160L1440,160L1440,320L1418.2,320C1396.4,320,1353,320,1309,320C1265.5,320,1222,320,1178,320C1134.5,320,1091,320,1047,320C1003.6,320,960,320,916,320C872.7,320,829,320,785,320C741.8,320,698,320,655,320C610.9,320,567,320,524,320C480,320,436,320,393,320C349.1,320,305,320,262,320C218.2,320,175,320,131,320C87.3,320,44,320,22,320L0,320Z"></path></svg>
        </div>
    );
};

export default ProductForm;
