import React, {useEffect} from 'react';
import './App.css';
import 'animate.css';
import { Route, Routes} from "react-router-dom"
import Entry from './components/Entry';
import Contact from './components/Contact';
import ForgotPassword from './components/ForgotPassword';
import PasswordResetForm from './components/PasswordResetForms';
import { ActivePageProvider } from './components/ActivePageContext';
import ProductCategory from './components/ProductCategory';
import PrivateRoutes from './components/ProtectedRoutes';
import ProductDetails from './components/ProductDetails';
import Search from './components/Search';
import ProductForm from './components/ProductForm';
import Login from './components/Login';
import Register from './components/Register';
import Activation from './components/Activation';
import Dashboard from './components/Dashboard';
import { useNavContext } from './components/NavContext';
import useFetch from './components/useFetch';
import { domain } from './components/Domain';
import { SessionIdProvider } from './components/SessionContext';
import Cart from './components/Cart';
import Quotation from './components/Quotation';


function App() {
  const {data, loading, error, refresh, setRefresh} = useFetch(`${domain}/categories`);
  const { mainNav, setMainNav } = useNavContext();

  useEffect(()=>{
    setMainNav(data)
  },[data])
  return (
    <div className="App">
      <ActivePageProvider>
        <SessionIdProvider>
          <Routes>
            <Route path='/' element={<Entry />} />
            <Route path='/login' element={<Login />} />
            <Route path='/register' element={<Register />} />
            <Route path='/accounts/activation' element={<Activation />} />
            <Route path='/search' element={<Search />} />
            <Route path='/forgot-password' element={<ForgotPassword />} />
            <Route path='/accounts/password-reset/:slug' element={<PasswordResetForm />} />
            <Route path='/cart' element={<Cart />} />
            <Route path='/contact-us' element={<Contact />} />
            <Route path='/products/:slug' element={<ProductCategory />} />
            <Route path='/products-details/:slug' element={<ProductDetails />} />
            <Route path='/quotation' element={<Quotation />} />
            <Route element={<PrivateRoutes />}>
              <Route path='/dashboard' element={<Dashboard />} />
              <Route path='/add-product' element={<ProductForm />} />
            </Route>
          </Routes>
          </SessionIdProvider>
      </ActivePageProvider>
    </div>
  );
}

export default App;