import React from 'react'
import "../css/CartButton.css"
import { useSessionId } from './SessionContext'
import { useNavigate } from 'react-router-dom'

const CartButton = () => {
    const navigate = useNavigate()
    const { cartItems } = useSessionId()
    return (
        <div className='cartBtn'>
            <button type="button" onClick={()=>navigate('/cart')} class="btn btn-primary position-relative">
                <i class="fa-solid fa-cart-shopping"></i>
                <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                    {cartItems.length}
                    <span class="visually-hidden">items</span>
                </span>
            </button>
        </div>
    )
}

export default CartButton