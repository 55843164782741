import React, { useState, useEffect, useRef } from 'react';
import useApiRequest from './useApiRequest';
import { Link } from 'react-router-dom';
import { domain } from './Domain';
import "../css/CategoryComponent.css"
import ProductForm, { notify } from './ProductForm';
import { DNA } from 'react-loader-spinner'

const CategoryList = ({ products, onDelete, onEdit, loading }) => {
    return (
        <ul className='item m-0 p-0'>
        {loading ? 
        <p className='d-flex gap-4 align-items-center'>
            <DNA
                visible={true}
                height="80"
                width="80"
                ariaLabel="dna-loading"
                wrapperStyle={{}}
                wrapperClass="dna-wrapper"
            />
        <span>Loading products ...</span></p>
        :
        products && products.map((product) => (
            <li key={product.id} className='d-flex justify-content-between align-items-center border-bottom p-2 mb-2 rounded'>
                <div className='d-flex align-items-center gap-2'>
                    <img style={{width:"15%"}} src={domain+product.image} />
                    <p>{product.product_name} {product.part_number && <span className='text-muted'>({product.part_number})</span>}<br />{product.condition}</p>
                </div>
                <div className='d-flex gap-2'>
                    <button onClick={() => onDelete(product.id)} className='btn btn-sm btn-danger'>Delete</button>
                    <button onClick={() => onEdit(product)} className='btn btn-sm btn-success'>Edit</button>
                </div>
            </li>
        ))}
        </ul>
    );
};

const ProductComponent = () => {
    const [editingId, setEditingId] = useState(null);
    const [products, setProducts] = useState([]);
    const [selected, setSelected] = useState(null)
    const [image, setImage] = useState(null)
    const [coverImage, setCoverImage] = useState(null)
    const [datasheet, setDatasheet] = useState(null)
    const { postRequest, getRequest, putRequest, deleteRequest } = useApiRequest();
    const [images, setImages] = useState([])
    const [datasheets, setDatasheets] = useState([])
    const coverRef = useRef()
    const imageRef = useRef()
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        fetchProducts();
    }, []);

    useEffect(() => {
        fetchImages();
        fetchDatasheets()
    }, [editingId]);

    const fetchProducts = async () => {
        const response = await  getRequest(`${domain}/products`);
        setProducts(response);
        setLoading(false)
    };

    const fetchImages = async () => {
        const response = await  getRequest(`${domain}/images?product=${editingId}`);
        setImages(response);
    };

    const fetchDatasheets = async () => {
        const response = await  getRequest(`${domain}/datasheets?product=${editingId}`);
        setDatasheets(response);
    };

    const handleDelete = async (id) => {
        if(window.confirm("are your sure you want to delete this item")){
            const response = await deleteRequest(`${domain}/products/${id}`);
            setProducts(products.filter((category) => category.id !== id));
        }
    };

    const handleEdit = (product) => {
        setEditingId(product.id);
        setSelected(product)
    };

    const handleAddImage = async() => {
        if(image === null){
            notify("please set an image to be added", "error")
            return
        }
        const formData = new FormData()
        formData.append("image", image)
        formData.append("product", parseInt(editingId))
        const response = await postRequest(`${domain}/images`, formData)
        if(response){
            notify("Image added successfully", "info")
            fetchImages()
            setImage(null)
        }
    }
    const handleDeleteImage = async(id) => {
        if(window.confirm("are your sure you want to delete this image")){
            const response = await deleteRequest(`${domain}/images/${id}`);
            if(response){
                fetchImages()
            }
        }
    }

    const handleAddDatasheet = async() => {
        if(datasheet === null){
            notify("please set a file to be added", "error")
            return
        }
        const formData = new FormData()
        formData.append("datasheet", datasheet)
        formData.append("product", parseInt(editingId))
        const response = await postRequest(`${domain}/datasheets`, formData)
        if(response){
            notify("Datasheet added successfully", "info")
            fetchDatasheets()
            setDatasheet(null)
        }
    }

    const handleDeleteDatasheet = async(id) => {
        if(window.confirm("are your sure you want to delete this Datasheet")){
            const response = await deleteRequest(`${domain}/datasheets/${id}`);
            if(response){
                fetchDatasheets()
            }
        }
    }

    const handleCoverImage = async() => {
        if(coverImage === null){
            notify("please set a cover image to be updated to", "error")
            return
        }
        const formData = new FormData()
        formData.append("image", coverImage)
        //formData.append("product", parseInt(editingId))
        const response = await putRequest(`${domain}/products/${editingId}`, formData)
        if(response){
            imageRef.current.src = domain+response.image
            notify("Cover Image updated successfully", "info")
            setCoverImage(null)
        }
    }

    return (
        <div>
            {editingId ? 
                <>
                    <div className='d-flex justify-content-end'>
                        <button className='btn btn-sm btn-danger' onClick={()=>setEditingId(null)}>cancel editing</button>
                    </div>
                    <ProductForm editingId={editingId} selected={selected} fetchProducts = {fetchProducts}/>
                    <h4>Product Cover Image</h4>
                    <div className='coverUpdate'>
                        <img ref={imageRef} src={domain+selected.image} alt={editingId}/>
                        <input type='file' accept='image/*' ref={coverRef} onChange={(e)=>setCoverImage(e.target.files[0])} name='image' hidden/>
                        <button className='btn btn-success' onClick={()=>coverRef.current.click()}>select cover image</button>
                        {coverImage && <button className='btn btn-primary ms-2' onClick={handleCoverImage}>update cover now</button>}
                    </div>
                    <h4>Other Product Images</h4>
                    <div className='d-flex flex-wrap imagesDiv'>
                        <div className='w-100'>
                            <h6>Add Images to selected product</h6>
                            <input type='file' onChange={(e)=>setImage(e.target.files[0])} className='border-0' accept='image/*' name='image'  />
                            <button className='btn btn-sm mt-2 mb-2 btn-success' onClick={handleAddImage}>Add Image</button>
                        </div>
                        {images && images.length > 0 && images.map(img => (
                            <div key={img.id}>
                                <img className='w-100' src={domain + img.image} alt='img' />
                                <button onClick={() =>handleDeleteImage(img.id)} className='btn btn-sm btn-danger mt-2'>delete product image</button>
                            </div>
                        ))}
                    </div>
                    <h4 className='mt-4'>Product Datasheets</h4>
                    <div>
                        <div className='w-100'>
                            <h6>Add Datasheets to selected product</h6>
                            <input type='file' onChange={(e)=>setDatasheet(e.target.files[0])} className='border-0' accept='application/pdf' name='datasheet'  />
                            <button className='btn btn-sm mt-2 mb-2 btn-success' onClick={handleAddDatasheet}>Add Datasheet</button>
                        </div>
                        {datasheets && datasheets.length > 0 && datasheets.map(item => (
                            <div key={item.id}>
                                <Link className='d-block text-dark mt-2 mb-2' key={item.url} to={domain+item.datasheet}><i class="fa-solid fa-file-pdf text-danger"></i> {item.datasheet.replace("datasheets/","").replace("/media/","")}</Link>
                                <button onClick={() =>handleDeleteDatasheet(item.id)} className='btn btn-sm btn-danger'>delete datasheet</button>
                            </div>
                        ))}
                    </div>
                </>
            : 
                <CategoryList
                    products={products}
                    onDelete={handleDelete}
                    onEdit={handleEdit}
                    loading = {loading}
                />
            }
            
        </div>
    );
};

export default ProductComponent;