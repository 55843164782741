

import React, { createContext, useState, useContext } from 'react';

const NavContext = createContext();

export const useNavContext = () => useContext(NavContext);

export const NavProvider = ({ children }) => {
    const [mainNav, setMainNav] = useState([]);

    return (
        <NavContext.Provider
            value={{
                mainNav,
                setMainNav
            }}
        >
            {children}
        </NavContext.Provider>
    );
};
