import React, {useState, useEffect} from 'react'
import Header from './Header'
import { Link, useParams } from 'react-router-dom';
import ProductCard from './ProductCard'
import { ProductCardSkeleton } from './ProductCard'
import "../css/ProductDetails.css"
import ProductImageSlider from './ProductImageSlidder';
import useFetch from './useFetch';
import { domain } from './Domain';
import CartButton from './CartButton';
import useApiRequest from './useApiRequest';
import { useSessionId } from './SessionContext';

const ProductDetails = () => {
    let { slug } = useParams();
    const {data, loading, error, refresh, setRefresh} = useFetch(`${domain}/products/${slug}`);
    const [count, setCount ] = useState(1)

    const [isLoading, setIsLoading] = useState(true);
    const [add, setAdding] = useState(false);
    const [products, setProducts] = useState([]);

    const { postRequest } = useApiRequest()
    const { sessionId, handleFetch } = useSessionId()

    const handleAddToCart = async() => {
        setAdding(true)
        const formData = new FormData()
        formData.append("id", slug)
        formData.append('session-id', sessionId)
        formData.append('quantity', count)
        const data = await postRequest(`${domain}/add-to-cart`, formData)
        if(data){
            handleFetch()
            setAdding(false)
        }
        
    };

    const handleCount = (e) => {
        let value = e.target.value
        if(value <= 0){
            value = 1
        }
        setCount(value)
    }

    const productItems = [
        {
            id: 1,
            name: 'Product A',
            partNumber: 'PA-12345',
            stockAmount: 10,
            price: 29.99,
            image: 'https://via.placeholder.com/300x200', // Example image URL
        },
        {
            id: 2,
            name: 'Product B',
            partNumber: 'PB-67890',
            stockAmount: 5,
            price: 49.99,
            image: 'https://via.placeholder.com/300x200', // Example image URL
        },
        {
            id: 3,
            name: 'Product B',
            partNumber: 'PB-67890',
            stockAmount: 5,
            price: 49.99,
            image: 'https://via.placeholder.com/300x200', // Example image URL
        },
        {
            id: 4,
            name: 'Product B',
            partNumber: 'PB-67890',
            stockAmount: 5,
            price: 49.99,
            image: 'https://via.placeholder.com/300x200', // Example image URL
        },
        // Add more products as needed
    ];

    useEffect(()=>{
        setIsLoading(true);
        setTimeout(()=>{
            setProducts(productItems);
            setIsLoading(false);
        },1000)
    },[slug])

    const bytesToMB = (bytes) => {
        if (bytes === 0) return '0 MB';
      
        const mb = bytes / (1024 * 1024);
        return `${mb.toFixed(2)} MB`;
    };
      

    return (
        <div>
            <Header />
            <div className='pt-4'>
                {data &&
                <div className='productDetails flex-wrap gap-4'>
                    <div className='w-100'>
                        <h4>Product Details - {data && data.product_name}</h4>
                        <p className='text-success fw-bold'>{data.part_number}</p>
                    </div>
                    <div className='imgSlidder'>
                        <ProductImageSlider images={data.images} />
                    </div>
                    <div className='pDetails flex-grow-1'>
                        <p className='d-flex justify-content-end fw-bold text-muted'>Order Code: 22475</p>
                        <div className='p-3' style={{backgroundColor:"var(--background)"}}>
                            <h5 className='text-white'>Available to order</h5>
                            <p className='text-white'>Delivery around kampala</p>
                        </div>
                        <div className='bg-body-tertiary p-3 d-flex justify-content-between'>
                            <div className='fw-bold'>
                                <h5 className='fw-bold'>Ugx: {data.selling_price.toLocaleString()}/=</h5>
                                <p>(excl. VAT)</p>
                            </div>
                            <div className='text-muted fw-bold'>
                                <h5 className='fw-bold'>Ugx: {(data.selling_price*1.18).toLocaleString()}/=</h5>
                                <p>(inc. VAT )</p>
                            </div>
                        </div>
                        <div className='ps-3 pe-3 pt-1 pb-1 d-flex' style={{backgroundColor:"gray"}}>
                            <p className='w-50'>Price break</p>
                            <p className='w-50'>Per Unit</p>
                        </div>
                        <div className='ps-3 pe-3 pt-1 pb-1 d-flex'>
                            <p className='w-50'>1+</p>
                            <p className='w-50'>{data.selling_price.toLocaleString()}</p>
                        </div>
                        <div className='bg-body-tertiary p-3 d-flex gap-2'>
                            <input type='number' className='w-25 d-none' min={1} onChange={handleCount} placeholder='' value={count} />
                            <button className='btn btn-primary' onClick={handleAddToCart}>{add ? 'Adding To Cart' : 'Add To Cart'}</button>
                        </div>
                        <p className='ps-3'><span className='fw-bold'>Condition:</span> {data.condition}</p>
                        <p className='ps-3'><span className='fw-bold'>Brand:</span> {data.brand_name}</p>
                    </div>
                    <div className='p-4 w-100 border rounded mb-4'>
                        <h4>Product Details</h4>
                        <p dangerouslySetInnerHTML={{__html: data.details}}></p>
                        <p className='pt-1 pb-1 fw-bold text-muted'>Download the {data.name} Manual and Quick Start Guide from the links below:</p>
                        {data.datasheets && data.datasheets.map(item => (
                            <Link className='d-block text-dark' key={item.url} to={domain+item.url}><i class="fa-solid fa-file-pdf text-danger"></i> {item.name.replace("datasheets/","")} {bytesToMB(item.size)}</Link>
                        ))
                        }
                    </div>
                </div>
                }
            </div>
            <div className='productDetails d-none d-block bg-body-tertiary pt-4'>
                <h4>You May Also be Interested In ...</h4>
                <div className="product-list d-flex gap-3 flex-wrap w-100">
                    {isLoading ? (
                            <React.Fragment>
                                <ProductCardSkeleton />
                                <ProductCardSkeleton />
                                <ProductCardSkeleton />
                                <ProductCardSkeleton />
                                {/* Add more skeletons as needed */}
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                {products && products.map((product) => (
                                    <ProductCard
                                        key={product.id}
                                        name={product.name}
                                        partNumber={product.partNumber}
                                        stockAmount={product.stockAmount}
                                        price={product.price}
                                        image={product.image}
                                    />
                                ))}
                            </React.Fragment>
                        )}
                </div>
            </div>
            <CartButton />
        </div>
    )
}

export default ProductDetails