import React from 'react';

const NoSearchResults = () => {
  return (
    <div style={styles.container}>
      <div style={styles.content}>
        <i style={styles.icon} class="fa-solid fa-magnifying-glass"></i>
        <h2 style={styles.heading}>No Search Results Found</h2>
        <p style={styles.text}>Sorry, we couldn't find any matching results.</p>
      </div>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '68vh',
    backgroundColor: '#f0f0f0',
  },
  content: {
    textAlign: 'center',
    padding: '20px',
    backgroundColor: '#fff',
    borderRadius: '8px',
    boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
  },
  icon: {
    fontSize: '48px',
    color: '#f0ad4e', // Example icon color
    marginBottom: '20px',
  },
  heading: {
    fontSize: '24px',
    color: '#333',
    marginBottom: '10px',
  },
  text: {
    fontSize: '16px',
    color: '#666',
  },
};

export default NoSearchResults;
