import React, { useState, useEffect } from 'react';
import { useLoginContext } from './LoginContext';

//body: data !== null ? JSON.stringify(data) : null,

const useApiRequest = () => {
    const auth = useLoginContext();
    const [response, setResponse] = useState(null);
    const [error, setError] = useState(null);

    const sendRequest = async (url, method, data = null) => {
        try {
            let headers = {};

            if (auth.userInfo.token !== null) {
                headers.Authorization = `Token ${auth.userInfo.token}`;
            }

            const requestOptions = {
                method: method,
                headers: headers,
                body: data !== null ? data : null,
            };

            const apiResponse = await fetch(url, requestOptions);
            const responseData = await apiResponse.json();
            console.log(responseData)

            setResponse(() => responseData);
            return responseData

        } catch (err) {
            setError(err);
            return err
        }
    };
    
    useEffect(() => {
        // This effect will run whenever 'response' changes
        console.log('Response changed:', response);

        // You can perform other actions here based on the response change
        // For example, you can update other state variables or trigger additional actions.
    }, [response]);

    const postRequest = async (url, data) => {
        return await sendRequest(url, 'POST', data);
    };
    
    const putRequest = async (url, data) => {
        return await sendRequest(url, 'PUT', data);
    };
    
    const deleteRequest = async (url) => {
        return await sendRequest(url, 'DELETE');
    };
    
    const getRequest = async (url) => {
        return await sendRequest(url, 'GET');
    };

    return { postRequest, putRequest, deleteRequest, getRequest, response, setResponse, error };
};

export default useApiRequest;
