// InquiryForm.jsx

import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import useApiRequest from './useApiRequest';
import { domain } from './Domain';
import { notify } from './ProductForm';

const InquiryForm = ({ isOpen, onClose }) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [question, setQuestion] = useState('');
  const { postRequest } = useApiRequest();

  const handleSubmit = async(e) => {
    e.preventDefault();
    // You can handle form submission here, e.g., send the data to an API
    console.log({ name, email, phone, question });

    const formData = new FormData();
    formData.append("name", name)
    formData.append("email", email)
    formData.append("phone", phone)
    formData.append("question", question)
    const response = await postRequest(`${domain}/inquiries`, formData)
    if(response){
        notify("Inquiry Sent Out", "info")
        // Clear the form fields
        setName('');
        setEmail('');
        setPhone('');
        setQuestion('');

        // Close the modal
        onClose()
    }else{
        // Close the modal
        notify("Error sending the Inquiry", "error")
    }
  };

  return (
    <Modal show={isOpen} onHide={onClose} centered>
      <Modal.Header closeButton style={{background:"var(--background)"}}>
        <Modal.Title className='fs-5 text-white'>Create Your Inquiry Form</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="formName">
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter your name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </Form.Group>

          <Form.Group controlId="formEmail">
            <Form.Label>Email address</Form.Label>
            <Form.Control
              type="email"
              placeholder="Enter email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </Form.Group>

          <Form.Group controlId="formPhone">
            <Form.Label>Phone</Form.Label>
            <Form.Control
              type="tel"
              placeholder="Enter phone number"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              required
            />
          </Form.Group>

          <Form.Group controlId="formQuestion">
            <Form.Label>Your Question</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              placeholder="Ask your question here..."
              value={question}
              onChange={(e) => setQuestion(e.target.value)}
              required
            />
          </Form.Group>

          <Button variant="primary" type="submit" className='mt-2'>
            Submit Inquiry
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default InquiryForm;
